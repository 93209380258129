import { onMount } from "solid-js";
import { localItemsSync, remoteDB } from "../assets/js/pouchDB.ts";
import { $items } from "../stores/items.ts";
import { getAllItems } from "../assets/js/itemService.ts";
import { loadCurrentCategoryItems } from "../stores/category.ts";
import { debounce as _debounce } from "lodash-es";

export default function SyncButton() {
	let trolleyLogo: HTMLImageElement | null = null;

	onMount(() => {
		document.addEventListener("visibilitychange", async () => {
			if (document.visibilityState === "visible") {
				await forceSync();
			}
		});

		forceSync();

		/**
		 * listen for DB changes
		 */
		remoteDB
			.changes({
				since: "now",
				live: true,
			})
			.on("change", forceSync);

		document.addEventListener("forceSync", forceSync);
	});

	const trolleyAnimation = () => {
		let start: DOMHighResTimeStamp;
		const duration = 600;

		const step = (timestamp: DOMHighResTimeStamp) => {
			if (start === undefined) {
				start = timestamp;
			}

			const elapsed = timestamp - start;

			if (trolleyLogo) {
				trolleyLogo.style.transform = `rotate(${
					(elapsed * 360) / duration
				}deg)`;
			}

			if (elapsed < duration) {
				window.requestAnimationFrame(step);
			} else if (trolleyLogo) {
				trolleyLogo.style.transform = "rotate(0deg)";
			}
		};

		requestAnimationFrame(step);
	};

	const forceSync = _debounce(
		async () => {
			// initial item load to have something show quickly
			await loadCurrentCategoryItems();

			trolleyAnimation();
			const animationInterval = setInterval(trolleyAnimation, 1000);
			let syncTimeoutActionId: number = 0;

			try {
				await localItemsSync();
			} catch {
				console.error("Failed to sync to the remote database.");
			}

			clearTimeout(syncTimeoutActionId);

			const [allItems] = await Promise.all([
				getAllItems(),
				loadCurrentCategoryItems(),
			]);

			$items.set(allItems);

			clearInterval(animationInterval);
		},
		100,
		{ maxWait: 200 },
	);

	return (
		<img
			class="image is-64x64 pt-4 animate-grow-and-spin cursor-pointer"
			alt="Trolley Logo"
			src="/imgs/trolley.svg"
			ref={trolleyLogo}
			onClick={forceSync}
		/>
	);
}